.demo-box {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 8px #ccc;
  background-color: #fff;
  padding: 15px;
  min-height: 200px;
}

button.link {
  border: none;
  background: none;
}

.demo-box .refresh {
  display: inline-block;
  font-size: 25px;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 10px;
}

.demo-box .loader {
  font-size: 45px;
  background: #ccc;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.gray {
  color: #ccc;
  opacity: 0.8;
}
